<script>
  export let title;
</script>

<style lang="scss">
  @use 'static/breakpoints';
  .title {
    font-size: 2.25em;
    font-weight: 900;
    color: var(--primary-variant);
    line-height: 1;
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .title {
      font-size: 2em;
      margin-bottom: 30px;
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .title {
      font-size: 1.75em;
    }
  }
</style>

<div class="title">{title}</div>
