<script>
  import { onMount } from "svelte";
  import { goto } from "@sveltech/routify";

  import TransitionWrapper from "../_components/other/TransitionWrapper.svelte";
  import Header from "../_components/1_level/Header.svelte";
  import WeAre from "../_components/1_level/WeAre.svelte";
  import Metadata from "../_components/other/Metadata.svelte";
  import BreakwaterEmployer from "../_components/1_level/BreakwaterEmployer.svelte";
  import WeValue from "../_components/1_level/WeValue.svelte";
  import OurCulture from "../_components/1_level/OurCulture.svelte";
  import OurTeam from "../_components/1_level/OurTeam.svelte";
  import OurPeople from "../_components/1_level/OurPeople.svelte";
  import JoinUs from "../_components/1_level/JoinUs.svelte";
  import WeShare from "../_components/1_level/WeShare.svelte";
  import FindUs from "../_components/1_level/FindUs.svelte";
  import Footer from "../_components/1_level/Footer.svelte";
  import isDataLoaded from "../_tools/DataLoaded.js";

  // Default metadata we can have while loading the actual page data
  let metadata = {};
  let idHash;

  export let data = [];
  export let loading = true;

  async function fetchPage() {
    const response = await fetch(`https://api.breakwater.ee/api/home`);

    if (response.status === 200) {
      data = await response.json();
      loading = false;
      if (!!data.seo) {
        metadata.title = data.seo.meta_title;
        metadata.description = data.seo.meta_description;
        metadata.image = data.seo.meta_image;
      }
      return { data };
    }
    console.error(response.status, "Request failed");
    $goto("../../404");
  }

  onMount(() => {
    fetchPage().then(() => {
      idHash = location.hash.slice(1);
      if (idHash && document.getElementById(idHash))
        document.getElementById(idHash).scrollIntoView({
          behavior: "smooth",
        });
    });
  });
</script>

<Metadata {metadata} />
{#if !loading}
  <TransitionWrapper>
    {#if isDataLoaded(data.header)}
      <Header isHomepage={true} headerData={data.header} />
    {/if}
    {#if isDataLoaded(data.we_are)}
      <WeAre weAreData={data.we_are} />
    {/if}
    {#if isDataLoaded(data.employer)}
      <BreakwaterEmployer employerData={data.employer} />
    {/if}
    {#if isDataLoaded(data.we_value)}
      <WeValue weValueData={data.we_value} />
    {/if}
    {#if data.our_culture.display}
      <OurCulture ourCultureData={data.our_culture} />
    {/if}

    {#if isDataLoaded(data.our_team)}
      <OurTeam ourTeamData={data.our_team} />
    {/if}
    {#if isDataLoaded(data.our_people)}
      <OurPeople ourPeopleData={data.our_people} />
    {/if}
    {#if isDataLoaded(data.join_us)}
      <JoinUs joinUsData={data.join_us} />
    {/if}
    {#if isDataLoaded(data.we_share)}
      <WeShare weShareData={data.we_share} />
    {/if}
    {#if isDataLoaded(data.footer.items)}
      <FindUs findUsData={data.footer.items} address={data.footer.address} />
    {/if}
    {#if isDataLoaded(data.footer)}
      <Footer footerData={data.footer} />
    {/if}
  </TransitionWrapper>
{/if}
