<script>
    export let featureData;
    export let imagePosition;
</script>

<style lang="scss">
    @use 'static/breakpoints';
    .feature {
        padding: 120px 0;

        &--title {
            &-description {
                display: flex;
                align-items: center;
            }
        }

        &--row {
            max-width: 300px;

            &-block {
                &-title {
                    color: var(--secondary);
                    font-weight: 600;
                    text-transform: uppercase;
                }

                &-description {
                }

                &-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 240px;
                    padding: 30px;

                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        .feature {
            &--title {
                &-description {
                }
            }
            &--row {
                &-block {
                    margin-bottom: 30px;
                    &-title {
                    }
                    &-description {
                    }
                    &-image {
                        height: auto;
                        padding: 0 0 0 15px;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }
                &-block:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>

<div class="col-md-4 feature--row-block">
    <div class="row">
        {#if featureData.image && imagePosition === 'first'}
            <div class="col-4 col-md-12 feature--row-block-image">
                <img alt="bwt" src={featureData.image.url} loading="lazy" />
            </div>
            <div class="col-8 col-md-12">
                <div class="feature--row-block-title">{featureData.title}</div>
                <div class="feature--row-block-description">
                    {featureData.text}
                </div>
            </div>
        {/if}
        {#if featureData.image && imagePosition === 'last'}
            <div class="col-8 col-md-12">
                <div class="feature--row-block-title">{featureData.title}</div>
                <div class="feature--row-block-description">
                    {featureData.text}
                </div>
            </div>
            <div class="col-4 col-md-12 feature--row-block-image">
                <img alt="bwt" src={featureData.image.url} loading="lazy" />
            </div>
        {/if}
        {#if !featureData.image}
            <div class="col-md-12">
                <div class="feature--row-block-title">{featureData.title}</div>
                <div class="feature--row-block-description">
                    {featureData.text}
                </div>
            </div>
        {/if}
    </div>
</div>
