<script>
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";

  export let ourPeopleData = {};

  let scrollY;
  let initialScrollY;
  let expanded = false;
  let swiperContainer;
  let mySwiper;
  let carouselImageHeight;

  onMount(() => {
    mySwiper = new Swiper(swiperContainer, {
      speed: 400,
      spaceBetween: 100,
      slidesPerView: 1,
      loop: true,
      autoHeight: true
    });
  });

  const scrollTo = (position) => (scrollY = position);
  const showMoreOrLessClicked = () => {
    expanded = !expanded;
    expanded
      ? (initialScrollY = scrollY)
      : scrollY > initialScrollY
      ? scrollTo(initialScrollY)
      : null;
  };
  const scrollIntoView = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const slidePrev = () => mySwiper.slidePrev();
  const slideNext = () => mySwiper.slideNext();
  const slideTo = (index) => mySwiper.slideTo(index + 1);
</script>

<style lang="scss">
  @use 'static/breakpoints';
  @mixin galleryGridLayout($itemsPerRow, $visibleItems) {
    &--gallery {
      grid-template-columns: repeat($itemsPerRow, minmax(0, 1fr));
      .wrapper:nth-child(-n + #{$visibleItems}) {
        display: block;
      }
    }
  }

  .our_people {
    margin-top: 120px;
    padding-bottom: 120px;
    &--title {
      padding-bottom: 50px;
    }
    &--carousel,
    &--gallery {
      margin-bottom: 60px;
      &-image {
        img {
          max-width: 100%;
        }
      }
      &-name {
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: 600;
        color: var(--secondary);
      }
      &-title {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--grey);
      }
    }
    &--carousel {
      position: relative;
      &-content {
        padding-top: 48px;
      }
      &-slide {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 20px;
      }
    }
    &--gallery {
      display: grid;
      align-items: start;
      gap: 16px;
      margin-bottom: 0;
      word-break: break-word;
      text-align: center;
      .wrapper {
        display: none;
        cursor: pointer;
        &--expanded {
          display: block;
        }
      }
      &-image {
        padding-bottom: 10px;
      }
      &-name {
        font-size: 0.85em;
      }
      &-title {
        font-size: 0.65em;
      }
    }
    &--show-more {
      color: var(--primary);
      font-weight: 600;
      &-cell {
        display: table-cell;
        vertical-align: middle;
        text-transform: uppercase;
        img {
          max-width: 100%;
          min-width: 22px;
        }
        &--arrow {
          transform: rotate(90deg);
          transition: transform 0.3s ease-in-out;
          &-up {
            transform: rotate(-90deg);
          }
        }
      }
    }

    @media only screen and (min-width: breakpoints.$screen-lg) and (max-width: breakpoints.$screen-xl-with-swiper-buttons) {
      &--carousel {
        &-content {
          padding-right: 1.5em;
        }
      }
    }

    @media only screen and (max-width: breakpoints.$screen-lg) {
      &--title {
        padding-bottom: 0;
      }
      &--carousel {
        &-content {
          padding-top: 24px;
        }
        &-slide {
          display: block;
        }
        &-image {
          text-align: center;
        }
      }
      &--gallery {
        &-name {
          font-size: 1em;
        }
        &-title {
          font-size: 0.75em;
        }
      }
    }

    @media only screen and (min-width: breakpoints.$screen-md) {
      @include galleryGridLayout(8, 8);
    }

    @media only screen and (min-width: breakpoints.$screen-sm) and (max-width: breakpoints.$screen-md) {
      @include galleryGridLayout(4, 8);
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
      @include galleryGridLayout(2, 4);
    }
  }
</style>

<svelte:window bind:scrollY />

<section class="our_people container-fluid" id="our-people">
  <div class="container p-0">
    <div class="our_people--title">
      <SmallSectionHeading title={ourPeopleData.title} />
    </div>
    <div class="our_people--carousel">
      <div bind:this={swiperContainer} class="swiper-container">
        <div class="swiper-wrapper">
          {#each ourPeopleData.items as item, i}
            <div class="swiper-slide our_people--carousel-slide">
              <div
                bind:clientHeight={carouselImageHeight}
                class="our_people--carousel-image">
                <img alt={item.image.alt} src={item.image.url} loading="lazy"  />
              </div>
              <div class="our_people--carousel-content">
                <div class="our_people--carousel-name">{item.name}</div>
                <div class="our_people--carousel-title pb-4">
                  {item.job_name}
                </div>
                <div class="our_people--carousel-text">
                  {@html item.about}
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>

      <div
        class="swiper-button-prev"
        on:click={() => slidePrev()}
        style="top: {carouselImageHeight / 2}px" />
      <div
        class="swiper-button-next"
        on:click={() => slideNext()}
        style="top: {carouselImageHeight / 2}px" />
    </div>

    <div class="our_people--gallery">
      {#each ourPeopleData.items as item, i}
        <div
          class="wrapper"
          class:wrapper--expanded={expanded}
          on:click={() => {
            slideTo(i);
            scrollIntoView('our-people');
          }}>
          <div class="our_people--gallery-image">
            <img alt={item.image.alt} src={item.image.url} loading="lazy" />
          </div>
          <div class="our_people--gallery-name">{item.name}</div>
          <div class="our_people--gallery-title">{item.job_name}</div>
        </div>
      {/each}
    </div>

    <div class="our_people--show-more mt-4" on:click|preventDefault={showMoreOrLessClicked}>
      <div class="py-2 our_people--show-more-cell">
        <img
          alt="bwt"
          class="w-auto"
          src="../../images/Line1.webp"
          loading="lazy"
        />
      </div>
      <div
        class="py-2 px-4 our_people--show-more-cell"
        style="white-space: nowrap; cursor: pointer">
        {#if !expanded}SHOW MORE{:else}SHOW LESS{/if}
      </div>
      <div class="py-2 our_people--show-more-cell">
        <img
          class="our_people--show-more-cell--arrow"
          class:our_people--show-more-cell--arrow-up={expanded}
          alt="bwt"
          src="../../images/Arrow1.webp"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</section>
