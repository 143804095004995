<script>
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import SectionHeading from "../2_level/SectionHeading.svelte";
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
  import TopPosts from "../2_level/TopPosts.svelte";
  import BlogPostPreview from "../2_level/BlogPostPreview.svelte";
  import isDataLoaded from "../../_tools/DataLoaded.js";

  export let weShareData = {};
  let swiperContainer;
  let mySwiper;
  let thumbnailHeight;

  onMount(() => {
    mySwiper = new Swiper(swiperContainer, {
      speed: 400,
      spaceBetween: 100,
      slidesPerView: 1,
      loop: true,
      autoHeight: true
    });
  });

  const slidePrev = () => mySwiper.slidePrev();
  const slideNext = () => mySwiper.slideNext();
</script>

<style lang="scss">
  @use 'static/breakpoints';
  .we_share {
    padding-top: 120px;
    padding-bottom: 120px;
    &--carousel {
      position: relative;
    }
    &--top-posts {
      &--recent {
        padding-bottom: 50px;
      }
      &--popular {
        padding-bottom: 50px;
      }
      &--all {
        font-size: 1.5em;
        font-weight: 600;
        a {
          color: var(--secondary);
        }
      }
    }
  }
</style>

<section class="we_share container-fluid" id="we-share">
  <div class="container p-0">
    <SectionHeading title={weShareData.title} />
    <SmallSectionHeading title={'BLOG'} />
    <div class="we_share--carousel">
      <div bind:this={swiperContainer} class="swiper-container">
        <div class="swiper-wrapper">
          {#each weShareData.items as postPreview, i}
            <div class="swiper-slide we_share--carousel-slide">
              <div class="we_share--blog-post">
                <BlogPostPreview
                  postPreviewData={postPreview}
                  bind:thumbnailHeight />
              </div>
            </div>
          {/each}
        </div>
      </div>

      <div
        class="swiper-button-prev"
        on:click={() => slidePrev()}
        style="top: {thumbnailHeight / 2}px" />
      <div
        class="swiper-button-next"
        on:click={() => slideNext()}
        style="top: {thumbnailHeight / 2}px" />
    </div>

  </div>
</section>
