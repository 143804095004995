<script>
  import SectionHeading from "../2_level/SectionHeading.svelte";
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
  import transformText from "../../_tools/TransformText.js";

  export let joinUsData = {};
</script>

<style lang="scss">
  @use 'static/breakpoints';
  .join_us {
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: var(--primary);
    &--intro {
      font-size: 2em;
      color: var(--white);
      border-bottom: 2px solid var(--white);
    }
    &--open_positions {
      color: var(--white);
      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid var(--white);
      }
      &-title {
        font-size: 2em;
        font-weight: 700;
      }
      &-apply {
        a {
          white-space: nowrap;
          color: var(--white);
          .caret {
            padding-left: 4px;
            vertical-align: middle;
            font-size: 1.25em;
            color: var(--secondary);
          }
        }
      }
    }
    @media only screen and (min-width: breakpoints.$screen-sm) and (max-width: breakpoints.$screen-md) {
      &--intro {
        font-size: 1.75em;
      }
      &--open_positions {
        &-title {
          font-size: 1.75em;
        }
      }
    }
    @media only screen and (max-width: breakpoints.$screen-sm) {
      &--intro {
        font-size: 1em;
      }
      &--open_positions {
        &-title {
          font-size: 1.25em;
          word-break: break-word;
        }
        &-apply {
          a {
            .text {
              display: none;
            }
            .caret {
              padding-left: 0.5em;
              font-size: 2em;
            }
          }
        }
      }
    }
  }
</style>

<section class="join_us container-fluid" id="join-us">
  <div class="container p-0">
    <SectionHeading title={joinUsData.title} light={true} />
    <div class="join_us--currently_hiring pt-4">
      <SmallSectionHeading title={transformText('currently hiring')} />
    </div>
    <div class="join_us--intro pb-4">{joinUsData.description}</div>
    <div class="join_us--open_positions">
      {#each joinUsData.items as position}
        <div class="join_us--open_positions-wrapper py-3">
          <div class="join_us--open_positions-title">
            {transformText(position.title)}
          </div>
          <div class="join_us--open_positions-apply pr-2">
            <a href={position.link}>
              <span class="text">Apply</span>
              <span class="caret"> <i class="fas fa-caret-right" /> </span>
            </a>
          </div>
        </div>
      {/each}
    </div>
  </div>
</section>
