<script>
  import { onMount } from "svelte"
  import { Loader } from "@googlemaps/js-api-loader";

  export let mapData = [];
  export let address = '';

  let map;
  let mapContainer;
  let zoom = 15;
  let center = {lat: 59.41957285035697, lng: 24.80465920595497};
  const styles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f8f8f8"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ededed"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    }
  ];

  const mapsLoader = new Loader({
    apiKey: "AIzaSyBu1NyWBVpqaaAT6QxhyTouCsEqCXiM5GA",
    version: "weekly"
  });

  onMount(async () => {
    mapsLoader.load().then(() => {
      setMap();
      setMainMarker();
      setCustomMarkers();
    });
  });

  function setMap() {
    map = new google.maps.Map(mapContainer, {
      styles: styles,
      center: center,
      zoom: zoom,
    });
  }

  function setMainMarker() {
    const marker = new google.maps.Marker({
      position: center,
      map,
      title: "BreakWater Office Tallinn",
    });

    const infowindow = new google.maps.InfoWindow({
      content: `
        <div class="px-4" style="text-align: center; font-size: 1.5em"><b>BreakWater Office Tallinn</b></div>
        <div style="text-align: center; font-weight: 400">${address}</div>
      `,
    });

    marker.addListener("click", () => {
      infowindow.open(map, marker);
    });

    infowindow.open(map, marker);
  }
  
  function setCustomMarkers() {
    const markerCircleIcon = {
        path: ` M 100, 100
                m -75, 0
                a 75,75 0 1,0 150,0
                a 75,75 0 1,0 -150,0`,
        scale: 0.01,
        strokeColor: "#d75e2a",
        strokeWeight: 14,
      };
  
    mapData.forEach((item, index) => {
      const coords = item.coords.split(',');
      const latitudeLongitude = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1]),
      };

      index % 2 === 1 ? markerCircleIcon.strokeColor = "#0072ce" : markerCircleIcon.strokeColor = "#d75e2a";

      const marker = new google.maps.Marker({
        position: latitudeLongitude,
        map,
        title: item.title,
        icon: markerCircleIcon
      });

      const infowindow = new google.maps.InfoWindow({
        content: `
          <div class="px-4" style="text-align: center; font-size: 1.25em"><b>${item.title}</b></div>
          <div style="text-align: center; font-weight: 400">${item.address}</div>
        `,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    })
  }
</script>

<style lang="scss">
  .map {
    height: 650px;
  }
</style>

<div class="map" bind:this={mapContainer}></div>

