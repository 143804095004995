<script>
  import WeShareMobile from "./WeShareMobile.svelte";
  import SectionHeading from "../2_level/SectionHeading.svelte";
  import TopPosts from "../2_level/TopPosts.svelte";
  import BlogPostPreview from "../2_level/BlogPostPreview.svelte";
  import isDataLoaded from "../../_tools/DataLoaded.js";

  export let weShareData = {};
  const mobileBreakpoint = 768;
  let innerWidth;
</script>

<style lang="scss">
  .we_share {
    padding-top: 120px;
    padding-bottom: 120px;
    &--top-posts {
      &--recent {
        padding-bottom: 50px;
      }
      &--popular {
        padding-bottom: 50px;
      }
      &--all {
        font-weight: 600;
        a {
          color: var(--secondary);
        }
      }
    }
  }
</style>

<svelte:window bind:innerWidth />

{#if innerWidth <= mobileBreakpoint}
  <WeShareMobile {weShareData} />
{:else}
  <section class="we_share container-fluid" id="we-share">
    <div class="container p-0">
      <SectionHeading title={weShareData.title} />
      <div class="row mt-4 pt-4">
        {#each weShareData.items as postPreview, i}
          <div class="col we_share--blog-post">
            <BlogPostPreview
              postPreviewData={postPreview}
              revert={i % 2 === 1} />
          </div>
        {/each}
      </div>
    </div>
  </section>
{/if}
