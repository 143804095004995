<script>
  export let employerData = {};
</script>

<style lang="scss">
  @use 'static/breakpoints';

  ul {
    padding: 0;
    list-style: none;
  }

  ul li {
    margin-bottom: 0.5em;
  }

  .employer {
    min-height: 700px;
    padding: 30px 0;
    background-color: var(--primary);
    color: var(--white);
    position: relative;
    &--pseudo-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      background-size: cover;
      z-index: 1;
    }
    &--award {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &--award-img {
      height: 125px;
      width: auto;
      top: 100%;
      left: 100%;
    }
    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100%;
      position: relative;
      z-index: 3;
      padding-bottom: 30px;
    }
    &-intro {
      &--row {
        width: 100%;
      }
      &--title {
        font-size: 68px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 30px;
      }
    }
    &-note {
      &--title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
      }
      &--message {
        font-size: 20px;
      }
    }
  }
  .employer::before {
    display: block;
    position: absolute;
    content: " ";
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .employer {
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100%;
        position: relative;
        z-index: 3;
        padding-bottom: 30px;
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .employer {
      height: auto;
      padding: 0;
      &--pseudo-bg {
        position: relative;
        min-height: 220px;
        margin-bottom: 45px;
      }
      &--award-img {
        height: 25vw;
        width: auto;
      }
      .container {
        display: block;
        padding-bottom: 0;
      }
      &-intro {
        &--title {
          font-size: 2.5em;
          margin-bottom: 15px;
        }
      }
      &-note {
        &--message ul {
          margin-top: 5px;
        }
        &--message li {
          margin-bottom: 5px;
        }
      }
    }
    .employer::before {
      display: none;
    }
  }
</style>

<div class="container-fluid employer" id="employer">
  <div class="employer--pseudo-bg" style="background-image: url({employerData.image.url})">
    <div class="employer--award">
      <img
        class="employer--award-img"
        alt="KickOff 2021 award"
        src="/images/KICKOFF_2021_award.webp"
        loading="lazy"
      />
      <img
        class="employer--award-img"
        alt="KickOff 2022 award"
        src="/images/KICKOFF_2022_award.webp"
        loading="lazy"
      />
      <img
        class="employer--award-img"
        alt="KickOff 2023 award"
        src="/images/KICKOFF_2023_award.webp"
        loading="lazy"
      />
    </div>
  </div>
  <div class="container">
    <div class="row employer-intro--row">
      <div class="col-md-6 mb-5">
        <div class="employer-intro--title">
          {employerData.title}
        </div>
        <div class="employer-intro--message">
          <p>{employerData.description}</p>
        </div>
      </div>
      <div class="col-md-3 mb-5">
        <div class="employer-note--title">
            {employerData.thesis1.title}
        </div>
        <div class="employer-note--message">
          <ul>
            {#each employerData.thesis1.items as item}
              <li>{item}</li>
            {/each}
          </ul>
        </div>
      </div>
      <div class="col-md-3 mb-5">
        <div class="employer-note--title">
          {employerData.thesis2.title}
        </div>
        <div class="employer-note--message">
          <ul>
            {#each employerData.thesis2.items as item}
              <li>{item}</li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
