<script>
    import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
    import ShowMoreLine from "../2_level/ShowMoreLine.svelte";
    import FeatureTitleText from "../2_level/FeatureTitleText.svelte";
    export let weValueData;
</script>

<style lang="scss">
    @use 'static/breakpoints';
    .we-value {
        padding-top: 120px;
    }
    @media only screen and (max-width: breakpoints.$screen-sm) {
        .we-value {
            padding-top: 90px;
            &--intro {
                margin-bottom: 30px;
            }
        }
    }
</style>

<div class="container-fluid we-value" id="we-value">
    <div class="container p-0">
        <div class="row">
            <div class="col-lg-3">
                <SmallSectionHeading title="{weValueData.title}"/>
            </div>
            <div class="col-lg-9">
                <div class="we-value--intro">{weValueData.description}</div>
                <div class="row">
                    {#each weValueData.items as weValue, i}
                        <FeatureTitleText featureData="{weValue}" imagePosition="first"/>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</div>
