<script>
  import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
  import Map from "../2_level/Map.svelte"

  export let findUsData = [];
  export let address = '';
</script>

<style lang="scss">
  @use 'static/breakpoints';
  .find_us {
    &--title {
      padding: 50px 0;
    }
    &--maps {
      padding: 20px 0;
    }
    .illustration {
      position: absolute;
      bottom: -80px;
      right: 0;
    }
    @media only screen and (max-width: breakpoints.$screen-xl) {
      .illustration {
        display: none
      }
    }
    @media only screen and (max-width: breakpoints.$screen-md) {
      &--title {
        padding-bottom: 0;
      }
      &--maps {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  }
</style>

<section class="find_us container-fluid" id="find-us">
  <div class="container p-0">
    <div class="find_us--title">
      <SmallSectionHeading title="FIND_US" />
    </div>
    <div class="find_us--maps">
      <Map mapData={findUsData} address={address}></Map>
    </div>
  </div>
  <img class="illustration" alt="bwt" src="../../images/Illustration_Jevgeni.webp" loading="lazy" />
</section>
