<script>
    import { onMount } from "svelte";
    import Swiper from "swiper";
    import SectionHeading from "../2_level/SectionHeading.svelte";
    import ReadMore from "../2_level/ReadMore.svelte";

    export let ourTeamData = {};
    let swiperContainer;
    let mySwiper;

    onMount(() => {
        mySwiper = new Swiper(swiperContainer, {
            speed: 400,
            loop: true,
            spaceBetween: 30,
            centeredSlides: true,
            lazy: false,
            on: {
                click: function (swiper, e) {
                    if (e.srcElement.innerText === "Read more") {
                        mySwiper.update(); // will 'reset' already expanded slides to default size
                        this.slideTo(this.clickedIndex + 1);
                        let slide = e.srcElement.closest(
                            ".our_team--slide-wrapper"
                        );
                        let slideVisible = e.srcElement.closest(
                            ".our_team--slide"
                        );
                        slide.style.transition = "all .3s ease";
                        let positionInfo = slide.getBoundingClientRect();
                        if (positionInfo.width < 400) {
                            let width = positionInfo.width * 3 + 60;
                            slideVisible.style.width =
                                positionInfo.width + "px";
                            slide.style.width = width + "px";
                            slide.classList.toggle("expanded");
                        } else {
                            let width = (positionInfo.width - 60) / 3;
                            slide.style.width = width + "px";
                            this.slideTo(this.clickedIndex);
                        }
                    }
                },
            },
            breakpoints: {
                768: {
                    slidesPerView: 1,
                },
                1200: {
                    slidesPerView: 5,
                },
            },
        });
    });

    const slidePrev = () => mySwiper.slidePrev();
    const slideNext = () => mySwiper.slideNext();
</script>

<style lang="scss">
    @use 'static/breakpoints';
    .expanded .our_team--slide-hidden {
        width: 100%;
    }
    .our_team {
        padding-top: 120px;

        &--title {
            margin-bottom: -120px;
        }

        &--carousel {
            position: relative;
        }

        &--slide {
            width: 100%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            ::after {
                content: "";
                background-color: var(--primary);
                position: absolute;
                top: 160px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }

            &-image {
                overflow: visible;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 460px;
                }
            }

            &-title {
                color: var(--white);
                font-weight: bold;
                font-size: 22px;
                padding: 0 30px;
            }

            &-cta {
                padding: 15px 30px;
            }
            &-details {
                display: flex;
                flex-direction: column;
                width: 100%;
                min-width: 430px;
                overflow: hidden;
                padding: 190px 30px 20px 30px;
                color: var(--white);
                &-container {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    flex-basis: 0;
                    flex-grow: 1;
                    overflow-y: hidden;
                }
                &-text {
                    overflow-wrap: break-word;
                    max-height: 70%;
                    overflow: hidden;
                }
                &-progress {
                    align-self: flex-end;
                    width: 100%;
                }
            }
            &-wrapper {
                display: flex;
                overflow: hidden;
            }
        }
    }
    .bwt_progress {
        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-title {
            flex: 0 0 auto;
            width: 140px;
            text-align: left;
            white-space: nowrap;
        }
        &-bar {
            &-line {
                height: 10px;
                background-color: var(--secondary);
                width: 100%;
            }
            flex: 1 1 auto;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
        .our_team {
            padding-top: 90px;

            &--title {
                margin-bottom: -90px;
            }
        }
    }
</style>

<section class="our_team container-fluid" id="our-team">
    <div class="container p-0">
        <div class="our_team--title">
            <SectionHeading title={ourTeamData.title} />
        </div>

        <div class="our_team--carousel">
            <div bind:this={swiperContainer} class="swiper-container">
                <div class="swiper-wrapper">
                    {#each ourTeamData.items as item}
                        <div class="swiper-slide our_team--slide-wrapper">
                            <div class="our_team--slide">
                                <div class="our_team--slide-image">
                                    <img
                                      alt={item.image.alt}
                                      src={item.image.url}
                                      loading="lazy"
                                    />
                                </div>
                                <div class="our_team--slide-title">
                                    {item.title}
                                </div>
                                <div class="our_team--slide-cta">
                                    <div class="our_team--slide-cta-btn">
                                        <ReadMore light="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="our_team--slide-details">
                                <div class="our_team--slide-details-container">
                                    <div class="our_team--slide-details-text">
                                        {@html item.description}
                                    </div>
                                    <div
                                        class="our_team--slide-details-progress">
                                        <div class="bwt_progress">
                                            {#each item.options as progress}
                                                <div
                                                    class="bwt_progress-container">
                                                    <div
                                                        class="bwt_progress-title pr-2">
                                                        {progress.option}
                                                    </div>
                                                    <div
                                                        class="bwt_progress-bar">
                                                        <div
                                                            class="bwt_progress-bar-line"
                                                            style="width: {progress.percentage}%" />
                                                    </div>
                                                </div>
                                            {/each}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/each}
                </div>
            </div>

            <div class="swiper-button-prev" on:click={() => slidePrev()} />
            <div class="swiper-button-next" on:click={() => slideNext()} />
        </div>
    </div>
</section>
