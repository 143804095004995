<script>
    import SmallSectionHeading from "../2_level/SmallSectionHeading.svelte";
    import isDataLoaded from "../../_tools/DataLoaded.js";
    export let ourCultureData = {};
</script>

<style lang="scss">
    @use 'static/breakpoints';
    .our-culture {
        margin-top: 90px;
        &--photo-row {
            display: flex;
        }
        &--photo-image {
            display: flex;
            margin-bottom: 30px;
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
        &--content {
            display: flex;
            padding-left: 45px;

            &-follow {
                color: var(--secondary);
                font-weight: 600;
                text-transform: uppercase;
                line-height: 2;

                .fa-stack {
                    color: var(--dark);
                    width: 2em;
                }

                .fa-stack-2x {
                    font-size: inherit;
                    width: 2em;
                }
            }
        }
    }
    @media only screen and (max-width: breakpoints.$screen-md) {
        .our-culture {
            &--photo-row {
                margin: 30px 10px 0 10px;
            }
            &--photo-image {
                margin-bottom: 10px;
                padding: 0 5px;
            }
            &--content {
                padding-left: 15px;
                &-follow {
                    text-align: right;
                }
            }
        }
    }
</style>

<div class="container-fluid our-culture" id="our-culture">
    <div class="container p-0">
        <div class="row">
            <div class="col-md col-xl-8 our-culture--photo-row our-culture--photos order-12 order-md-1">
                <div class="row align-self-center">
                    {#each ourCultureData.items as item}
                        <div class="col-4 our-culture--photo-image">
                            <a href={item.url}>
                                <img
                                  class="preview--image"
                                  alt={item.meta.alt}
                                  src={item.url}
                                  loading="lazy"
                                />
                            </a>
                        </div>
                    {/each}
                </div>
            </div>
            <div class="col-md col-xl-4 our-culture--content order-1 order-md-12">
                <div class="align-self-center">
                    <SmallSectionHeading title={ourCultureData.title} />
                    <div class="mt-4">{ourCultureData.description}</div>
                    <div class="mt-4 align-middle our-culture--content-follow">
                        <span class="pr-2">Follow our team on</span>
                        {#if isDataLoaded(ourCultureData.facebook)}
                            <a href={ourCultureData.facebook}>
                                <span
                                    class="fa-stack"
                                    style="vertical-align: top;">
                                    <i class="fas fa-circle fa-stack-2x" />
                                    <i
                                        class="fab fa-facebook-f fa-stack-1x fa-inverse" />
                                </span>
                            </a>
                        {/if}
                        {#if isDataLoaded(ourCultureData.instagram)}
                            <a href={ourCultureData.instagram}>
                                <span
                                    class="fa-stack"
                                    style="vertical-align: top;">
                                    <i class="fas fa-circle fa-stack-2x" />
                                    <i
                                        class="fab fa-instagram fa-stack-1x fa-inverse" />
                                </span>
                            </a>
                        {/if}
                        <a href="https://www.tiktok.com/@breakwater_technology?lang=en">
                                <span
                                        class="fa-stack"
                                        style="vertical-align: top;">
                                    <i class="fas fa-circle fa-stack-2x" />
                                    <i
                                            class="fab fa-tiktok fa-stack-1x fa-inverse" />
                                </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
