<script>
  export let title;
  export let light = false;
</script>

<style lang="scss">
  @use 'static/breakpoints';
  .title {
    font-size: 120px;
    font-weight: 900;
    color: var(--white-variant);
    &--light {
      color: var(--primary-light);
    }
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .title {
      font-size: 60px;
      line-height: 1;
      margin-bottom: 30px;
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .title {
      font-size: 2.75em;
    }
  }
</style>

<div class="title" class:title--light={light}>{title}</div>
